import * as Yup from 'yup';
import React, { useState } from 'react'; 

import { Formik } from 'formik';
import { Link as RouterLink } from 'react-router-dom';

import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Checkbox,
  Typography,
  Link,
  FormControlLabel,
  CircularProgress,
  Grid,
  Dialog,
  DialogTitle,
  useTheme,
  DialogContent,  
  useMediaQuery
} from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import useRefMounted from 'src/hooks/useRefMounted';
import { useTranslation } from 'react-i18next';

const LoginAmplify = () => {
  const [emailVerification, toggleEmailVerification] = useState(false);
  const { login, verifyCode, resendCode } = useAuth();
  const isMountedRef = useRefMounted();
  const { t } = useTranslation();
  const theme = useTheme();
  
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Formik
      initialValues={{
        email:  '', // 'dev@halfnhalf.app',
        password:  '', // '123qweasdzxc',
        code: "",
        terms: true,
        submit: null
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t('The email provided should be a valid email address'))
          .max(255)
          .required(t('The email field is required')),
        password: Yup.string()
          .max(255)
          .required(t('The password field is required')),
        terms: Yup.boolean().oneOf(
          [true],
          t('You must agree to our terms and conditions')
        )
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          if(values.code.length !== 0){
            let resp = await verifyCode(values.email, values.code); 
            console.log(JSON.stringify(resp, null, 4));
          }
          let resp = await login(values.email, values.password);
          if(resp.error)
            throw new Error();
          toggleEmailVerification(false);

          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          console.error(JSON.stringify(err, null, 4));
          
          // UserNotConfirmedException

          if(err.code === "UserNotConfirmedException"){
            toggleEmailVerification(true);
          }

          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        submitForm,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            margin="normal"
            autoFocus
            helperText={touched.email && errors.email}
            label={t('Email address')}
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            margin="normal"
            helperText={touched.password && errors.password}
            label={t('Password')}
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.terms}
                  name="terms"
                  color="primary"
                  onChange={handleChange}
                />
              }
              label={
                <>
                  <Typography variant="body2">
                    {t('I accept the')}{' '}
                    <Link component="a" href="#">
                      {t('terms and conditions')}
                    </Link>
                    .
                  </Typography>
                </>
              }
            />
            <Link component={RouterLink} to="/account/recover-password">
              <b>{t('Lost password?')}</b>
            </Link>
          </Box>

          {Boolean(touched.terms && errors.terms) && (
            <FormHelperText error>{errors.terms}</FormHelperText>
          )}

          <Button
            sx={{
              mt: 3
            }}
            color="primary"
            startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
            disabled={isSubmitting || !(values.email && values.email.length > 4)}
            type="submit"
            fullWidth
            size="large"
            variant="contained"
          >
            {t('Sign in')}
          </Button>

            
      <Dialog
        // fullWidth
        maxWidth="md"
        open={emailVerification}
        // onClose={handleCreateInvoiceClose}
      >
        <DialogTitle
          sx={{
            p: 3
          }}
        >
          <Typography variant="h4" gutterBottom>
            {t('Please input the MFA code sent to your inbox')}
          </Typography> 
        </DialogTitle>
        <DialogContent
          dividers
          sx={{
            p: 3
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Box pb={1}>
                <b>{t('MFA code')}:</b>
              </Box>
              <TextField
                error={Boolean(touched.number && errors.number)}
                helperText={touched.number && errors.number}
                name="code"
                placeholder={t('Input MFA Code')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.code}
                variant="outlined"
              />


            </Grid>  
          </Grid>
        </DialogContent>
        
        <Box
          sx={{
            display: { xs: 'block', sm: 'flex' },
            alignItems: 'center',
            justifyContent: 'space-between',
            p: 3
          }}
        >
          <Box>
            <Button 
              fullWidth={mobile} 
              startIcon={
                isSubmitting ? <CircularProgress size="1rem" /> : null
              } 
              onClick={async() => {
                console.log(JSON.stringify(values, null, 4));
                let resp = await resendCode(values.email);
                console.log(JSON.stringify(resp, null, 4));
              }}
              variant="contained"
              size="large"
            >
              {t('Resend Code')}
            </Button>
          </Box>
          <Box>
            <Button
              fullWidth={mobile} 
              startIcon={
                isSubmitting ? <CircularProgress size="1rem" /> : null
              }
              onClick={async () => {
                await submitForm();
              }}
              disabled={Boolean(errors.submit) || isSubmitting || values.code.length === 0}
              variant="contained"
              size="large"
            >
              {t('Submit')}
            </Button>
          </Box>
        </Box>
      </Dialog>

        </form>
      )}
    </Formik>
  );
};

export default LoginAmplify;
